import { validateEmail } from './validations';

document.addEventListener('DOMContentLoaded', () => {
  const emailFieldsSelector = 'input[type=email], input[data-email-validation]';
  const emailFields = document.querySelectorAll(emailFieldsSelector);
  if (emailFields.length === 0) {
    return;
  }

  emailFields.forEach(field => {
    field.addEventListener('input', () => validateEmailInput(field));

    let form = field.closest('form');
    if (form) {
      form.addEventListener('submit', e => {
        let valid = Array.from(form.querySelectorAll(emailFieldsSelector)).every(field =>
          validateEmailInput(field)
        );
        if (!valid) e.preventDefault();
      });
    }
  });
});

function validateEmailInput(element) {
  // TODO: это костыль, чтобы пользователи у которых в имейле есть символ + или ' могли залогиниться
  let allowExtraCharacters = !element.value.includes('+') || !element.value.includes("'");
  if (element.hasAttribute('data-allow-extra-characters')) {
    allowExtraCharacters = true;
  }
  let valid = validateEmail(element.value) && allowExtraCharacters;
  changeElementValidityStatus(element, valid);
  return valid;
}

function changeElementValidityStatus(element, valid, labelParams = {}) {
  element.classList.toggle('untouched', false);
  element.classList.toggle('touched', true);
  element.classList.toggle('valid', valid);
  element.classList.toggle('invalid', !valid);

  if (element.id) {
    let errorLabel = document.querySelector(`[data-validation-error="${element.id}"]`);
    let errorTitle = document.querySelector(`[title-validation-error="${element.id}"]`);
    if (errorLabel) {
      errorLabel.classList.toggle('hidden', valid);
      errorTitle.classList.toggle('validation_error-title', !valid);
      Object.entries(labelParams).forEach(([key, value]) => {
        errorLabel.innerHTML = errorLabel.innerHTML.replace(key, value);
      });
    }
  }
}
